import { useState, useEffect } from 'react';
import { Grommet, Text, Grid, Box } from 'grommet';
import { DateTime, Duration } from 'luxon';

// AI'm not sure if it's currently possible to pass the Card data separately
// This way the end user can configure timezones and displayed locations easily
// This could be e.g. fetched from an API endpoint or injected by another component
const locations = [
  {
    timezone: 'Asia/Tokyo',
    label: 'Tokyo, Japan',
  },
  {
    timezone: 'Asia/Kolkata',
    label: 'Bangalore, India',
  },
  {
    timezone: 'Europe/London',
    label: 'London, UK',
  },
  {
    timezone: 'America/New_York',
    label: 'RTP, USA',
  },
  {
    timezone: 'America/Los_Angeles',
    label: 'San Jose, USA',
  },
];

// Internal-use component to render each timezone's clock widget
const ClockTime = ({ timezone, label, currentTime }) => {
  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  function isNegativeDuration(duration) {
    return duration.valueOf() < 0;
  }

  function formatDuration(duration, pattern) {
    if (isNegativeDuration(duration)) {
      return `-${duration.negate().toFormat(pattern)}`;
    } else {
      return `+${duration.toFormat(pattern)}`;
    }
  }

  const zoneTime = currentTime.setZone(timezone);
  const displayTime = zoneTime.setLocale('en-US').toLocaleString(DateTime.TIME_SIMPLE);

  const offset = Duration.fromObject({ minutes: zoneTime.offset - currentTime.offset }); // '5h, -3h30m'
  const relativeDate = capitalize(currentTime.plus({ minutes: zoneTime.offset - currentTime.offset }).toRelativeCalendar()); // 'Today', 'tomorrow', 'yesterday'

  let displayOffset = formatDuration(Duration.fromObject({ minutes: zoneTime.offset - currentTime.offset }), 'h:mm');
  if (offset.get('minute') % 60 == 0) displayOffset = displayOffset.slice(0, -3); // Trim padding 00m for on-the-hour offsets

  return (
    <Box border={{ color: 'light-4' }} round="3px" pad={{ bottom: 'small', top: 'small', left: 'medium', right: 'medium' }}>
      <Grid columns={['flex', 'auto']} align="center">
        <Box>
          <Text size="small" color="dark-3" margin={{ bottom: 'xxsmall' }}>
            {relativeDate}, {displayOffset}HRS
          </Text>
          <Text>{label}</Text>
        </Box>
        <Box>{displayTime}</Box>
      </Grid>
    </Box>
  );
};

// Takes some data attribute, an array of locations
// in the format {"timezone": "IANA/Timezone", "label": "Location label"}
const WorldClock = ({ data }) => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    // Update the current time value every second
    if (!data) return;
    const tick = setInterval(() => {
      const now = DateTime.now();
      setCurrentTime(now);
    }, 1000);
    return () => clearInterval(tick);
  }, [data]);

  return (
    (data && (
      <Grid fill gap="small">
        {/* Iterate over all defined locations to render one Clock widget per location */}
        {data.map((location) => (
          <ClockTime key={location.label} currentTime={currentTime} timezone={location.timezone} label={location.label}></ClockTime>
        ))}
      </Grid>
    )) ||
    null
  );
};

// To render on the test page
// Simulates Card width/padding
const WorldClockTest = () => {
  return (
    <Grommet>
      <Box margin="medium" width="450px">
        <WorldClock data={locations}></WorldClock>
      </Box>
    </Grommet>
  );
};

export default WorldClockTest;
